/* FloatingWrapper.css */
.floating-wrapper {
    display: flex;
    margin-bottom: 8px;
    margin-right: 8px;  
    flex: 1;
    padding: 8px; /* Add some padding if desired */
    background-color: white; /* Ensure background is white */
    border-radius: 4px; /* Optional: add rounded corners */
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px; /* Shadow effect */
    flex-direction: column;    
    justify-content: space-between;
    /* transition: transform 0.3s ease, box-shadow 0.3s ease; Smooth transition for shadow */
  }
/*   
  .floating-wrapper:hover {
    transform: translateY(-4px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2); 
  } */
