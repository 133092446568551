.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.invoice_table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.invoice_table th {
  text-align: left;
} 

.invoice_table tr.summary td:empty {
  color: inherit;
  background-color: inherit;
}

.invoice_table tr.summary td {
  color: white;
  background-color: black;
}

.invoice_table tr.summary td {
  border-top: 15px solid white;
}

.invoice_table tr.summary,
.invoice_table tr.sub-total {
  border-bottom: none;
  height: 2em;
  border-bottom: 15px solid white;
}

.invoice_table tr {
  border-bottom: 1px solid lightgray;
}

th.left,
td.left,
.left {
  text-align: left;
}

th.right,
td.right,
.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
